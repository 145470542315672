import Vue from "vue";
import Vuex from "vuex";
import { APIServices, timeBeforeCloseSession, defaultToken } from '../../constants/config';

Vue.use(Vuex);

export default {
  state: {
    user: null,
    userData: null,
    timeBeforeCloseSession: 0, // Miliseconds... If user start sessión, this var will should be decreased every n miliseconds, if counter becomes less or equal to zero, signOut action should be fired
    bIsHumanUser: false
  },
  getters: {
    isHumanUser(state){
      return state.bIsHumanUser;
    },
    loggedIn(state) {
      return !!state.user;
    },
    userData(state) {
      return state.userData;
    },
    user(state) {
      return state.user;
    },
    timeBeforeCloseSession: state => state.timeBeforeCloseSession,
  },
  mutations: {
    resetTimeBeforeCloseSession(state) {
      state.timeBeforeCloseSession = timeBeforeCloseSession
    },
    SET_USER(state, userData) {
      state.user = userData;
      Vue.prototype.$http.defaults.headers.common['Authorization'] = `Token ${userData.token}`;
      localStorage.setItem("user", JSON.stringify(userData));
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData;
    },
    CLEAR_USER_DATA(state, payload) {
      state.user = null;
      state.userData = null;
      localStorage.removeItem("user");
      delete Vue.prototype.$http.defaults.headers.common['Authorization'];
      Vue.prototype.$http.defaults.headers.common['Authorization'] = `Token ${defaultToken}`;
      if (payload && payload.redirectTo) {
        window.location = payload.redirectTo;
      }
    },
    SET_IS_HUMAN_USER(state, value){
      state.bIsHumanUser = value;
    }
  },
  actions: {
    async validateHumanUser({commit}, payload){
      let $http = Vue.prototype.$http;
      let url = APIServices.validate_human_user;
      
      return await $http.post(url, payload).then(({ data }) => {
        //console.log("data: ", JSON.stringify(data));
        commit("SET_IS_HUMAN_USER", data);
      });
    },
    async getToken({ commit }, credentials) {
      let $http = Vue.prototype.$http;
      let url = APIServices.token;
      //console.log("credentials: " + JSON.stringify(credentials));      
      return await $http.post(url, credentials).then(({ data }) => {
        //console.log("data: ", JSON.stringify(data));
        commit("SET_USER", data);
      });
    },
    async signIn(_, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.user;
      return await $http.post(url, payload.data)
    },
    async sendEmailConfirm(_, id) {
      let $http = Vue.prototype.$http;
      let url = APIServices.email + id + "/";
      return await $http.get(url)
    },
    async startPasswordReset(_, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.start_password_reset;
      return await $http.post(url, payload.data)
    },
    async ResetPassword(_, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.reset_password;
      return await $http.post(url, payload.data)
    },
    logout({ commit }, payload={}) {
      commit("CLEAR_USER_DATA", payload);
    },
    async UserData({ commit }) {
      let $http = Vue.prototype.$http;
      let url = APIServices.user_products;
      return await $http
        .get(url)
        .then(data => {
          return commit("SET_USER_PRODUCTS_DATA", data);
        })
        .fail(error => {
          error = error.responseJSON;
          return error.responseJSON;
        });
    },
    async getUserDetail({ commit, getters }) {
      let $http = Vue.prototype.$http;
      let url = APIServices.user_quick + getters.user.id + "/";
      return await $http
        .get(url)
        .then(result => {
          return commit("SET_USER_DATA", result.data);
        })
    },
    async updateOrCreateUser({ commit, getters }, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.user_quick;
      let result = null;
      if (payload.id) { // this view is only for update self user data
        url += getters.user.id + "/";
        result = await $http.put(url, payload.data).then(result => {
          commit("SET_USER_DATA", result.data.data); // Only if update makes update of userdata
          return result.data;
        })
      } else {
        result = await $http.post(url, payload.data).then(result => result.data);
      }
      
      return result;
    },
    async updateOrCreateUserAddress(_, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.user_address;
      let result = null;
      // console.log("ANTES DE LLAMAR A user_address: ", payload);
      // payload.data["user"] = getters.user.id // this view is only for update self user address
      if (payload.id) {
        url += payload.id + "/";
        result = await $http.put(url, payload.data).then(result => result.data)
      } else {
        result = await $http.post(url, payload.data).then(result => result.data);
      }
      return result;
    },
  }
};
