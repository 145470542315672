<template>
    <label class="t-label" :class="[showStatus && !valid ? 'text-red-700': '']">
        {{ text }} 
        <span v-if="required" class="text-red-700">*</span>
        <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="t-error-form-icon fa-1x"
            v-if="showStatus && !valid"
        />
        <font-awesome-icon
            :icon="['fas', 'check-circle']"
            class="t-succes-form-icon fa-xl"
            v-if="showStatus && valid"
        />
    </label>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        showStatus: {
            required: true,
            type: Boolean
        },
        valid: {
            required: true,
            type: Boolean
        },
        text: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        }
    }
}
</script>