import Vue from 'vue';
import { APIServices } from '../../constants/config';
export default {
  state: {
    bgApp: "",
    isMobileView: false,
    isUserSideBarOpen: false
  },
  getters: {},
  mutations: {
    setIsMobileView(state, newValue) {
      state.isMobileView = newValue;
      //console.log("Cambiando tipo de vista", state.isMobileView);
    },
    toogleUserSidebar(state) {
      state.isUserSideBarOpen = !state.isUserSideBarOpen;
    },
    setIsUserSideBarOpen(state, payload={}) {
      //console.log("Cambiando estatus del sidebar de ususario, retardo", payload.timeout);
      setTimeout(() => {
        state.isUserSideBarOpen = payload.status;
        //console.log("Cambiado");
      }, payload.timeout);
    },
    updateBgApp(state, newData) {
      state.bgApp = newData;
    }
  },
  actions: {
    async getZipCode(_, payload={}) {
      let $http = Vue.prototype.$http;
      let url = APIServices.zip_code + payload.id + "/";
      return await $http.get(url,{ params: payload.params}).then(response => response.data)
    },
    async getGender(_, payload={}) {
      let $http = Vue.prototype.$http;
      let url = APIServices.gender;
      return await $http.get(url,{ params: payload.params}).then(response => response.data)
    },
    async getCivilStatus(_, payload={}) {
      let $http = Vue.prototype.$http;
      let url = APIServices.civil_status;
      return await $http.get(url,{ params: payload.params}).then(response => response.data)
    },
  }
};
