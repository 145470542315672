<template>
  <div class="loader-container flex flex-row flex-wrap content-center">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="msg-loading flex justify-center w-full font-black">
      <ul>
        <li class="flex justify-center" v-for="(textLine, index) in loadingMessageList" :key="index">
          <template v-for="(letter, index) in textLine">
            <div v-if="letter == ' '" :key="index" class="mr-1" />
            <div v-else :key="index">{{ letter }}</div>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingMessageList: {
      type: Array,
      default: () => []
    }
  }
};
</script>
<style>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff4f;
  z-index: 300 !important;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #302562;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.msg-loading {
  position: relative;
  z-index: 0;
}

.msg-loading::before {
  content: '';
  z-index: 0;
  position: absolute;
  top: 0px;
  padding: 20px;
  width: 300px;
  height: 100%;
  background: #FFF;
  filter: blur(20px);
}

.msg-loading::after {
  content: '';
  z-index: 1;
  position: absolute;
  top: 0px;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0.296) 0%, transparent 50%);
  transform: rotateX(80deg);
}
.msg-loading div {
  text-shadow: 0px 0px 15px var(--primary);
  color: #3c0073;
  text-transform: uppercase;
}
@for $i from 1 through 80 {
  .msg-loading div:nth-child(#{$i}) {
    transition: all 10s ease-out;
    animation: vertical-shake 10s linear infinite;
    animation-delay: $i * 0.01s;
  }
}

@keyframes vertical-shake {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  1% {
    transform: translateY(20px);
  }
  2% {
    transform: translateY(-10px);
  }
  3% {
    opacity: 1;
    transform: translateY(0px);
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
  }
}
</style>