import Vue from "vue";
import Vuex from "vuex";
import ShoppingCar from "./ShoppingCar";
Vue.use(Vuex);
export default {
    state: {
        shoppingCar: new ShoppingCar([])
    },
    getters: {
        shoppingCar: state => state.shoppingCar,
    },
    mutations: {
        setShoppingCar: (state, data) => state.shoppingCar = data,
    }
};
