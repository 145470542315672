// Initial Configs

//? DEV
// export const APIEndpoint = "http://127.0.0.1:8000/api/";
// export const APIEndpoint = "https://apidev.sabar.mx/api/";

//? QA
// export const APIEndpoint = "https://apiqa.sabar.mx/api/";

//? PROD
export const APIEndpoint = "https://api.sabar.mx/api/";


//keys
export const siKey = "6LdN_V0iAAAAAJ_7p1vJ_IGXuYUEq74Leh5Dop-z"
export const defaultToken = "a61752384137d3801a1af22236cc772aea5442e9"


//? STRIPE DEVELOP
export const stripePk = "pk_test_51IQIz9LI5lYeWEOtf6vd9eSI62MIwGnCI0GJ6AsJeZiD9GTarHP0bqHcdptPcI2XoeZCDlDW9UQ7suGE2ZLW8tX600Y5ZXSLEG"

//? STRIPE PRODUCTION
// export const stripePk = "pk_live_51IQIz9LI5lYeWEOtFcmJv6g66zUCgLc5K6xcg0lAeK3vHw5gM5LzYWVKLaW3YKIMlfDSUHoXdfTC1ABugV423Yj700rlToPZmL"


export const timeBeforeCloseSession = 10 * 60 * 1000;
//# Regex rfc sin homoclave
export const rfcRegex = /^([A-ZÑ&]{3,4})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))/
//# | Nombre 3/4 |fecha 6| code 3  |
//export const rfcRegexHomoCve =   /^[ña-z]{3,4}[0-9]{6}[0-9a-z]{3}$/i
export const rfcRegexHomoCve = /^[0-9a-z]{3}$/i
// Regex para validar enteros positivos
export const intRegexPositive = "^([1-9]+\\d*)|[0]"

export const whatsapp = 5215530517853;

import moment from "moment";

//Función para validar la edad mínima permitida: >= 18 años
export const isMinValidAge = (value) => {
  let minValidAge = 18;
  let born_dt = moment(value).format("YYYY-MM-DD");
  let calculated_age = moment().diff(born_dt, "years", true);
  if (parseInt(calculated_age) < minValidAge) {
    return false;
  } else {
    return true;
  }
}

//Función para validar la edad máxima permitida: >= 70 años
export const isMaxValidAge = (value , categoryIds) => {
  let maxValidAge = 70

  if(categoryIds == 12){
    // SEGURO VIAJERO
    maxValidAge = 85
  } else if(categoryIds == 7){
    // SEGURO GASTOS MÉDICOS
    maxValidAge = 60
  }

  let born_dt = moment(value).format("YYYY-MM-DD");
  let calculated_age = moment().diff(born_dt, "years", true);
  if (parseInt(calculated_age) > maxValidAge) {
    return false;
  } else {
    return true;
  }
}



export const isRFCValid = (rfc , name , first_name , last_name , birth_date) => {

  // alert(rfc)
  // alert(name)
  // alert(first_name)
  // alert(last_name)
  // alert(birth_date)
  return false


  // rfc = rfc.toUpperCase()
  // name = name.toUpperCase()
  // first_name = first_name.toUpperCase()
  // last_name = last_name.toUpperCase()

  // if(rfc.length == 10){
  //   var rfc_first = first_name.substring(0,1)
  //   var rfc_second = () => {
  //       var vowels = ["A" , "E" , "I" , "O" , "U"]
  //       var rfc_second = ""
  //       var rfc_second_found = false
  //       for (let i = 0; i < first_name.length; i++) {
  //           var char = first_name.charAt(i)
  //           if(!rfc_second && vowels.indexOf(char)!= -1){
  //               // First Vowel Found
  //               rfc_second = char
  //               rfc_second_found = true
  //           }
  //       }
  //       return rfc_second
  //   }
  //   var rfc_third = last_name.substring(0,1)
  //   var rfc_fourth = name.substring(0,1)

  //   var rfc_year = birth_date.substring(2,4)
  //   var rfc_month = birth_date.substring(5,7)
  //   var rfc_day = birth_date.substring(8,10)

  //   var rfc_build = `${rfc_first}${rfc_second()}${rfc_third}${rfc_fourth}${rfc_year}${rfc_month}${rfc_day}`

  //   if(rfc_build == rfc){
  //       return true
  //   } else {
  //       return false
  //   }

  // } else {
  //   return false
  // }

}



























//Función para validar número entero positivo
export const isPositiveInteger = (value) => {
  if(parseFloat(value) > 0.0){
    return true;
  } else {
    return false;
  }
}

//Función para validar número entero positivo
export const validateName = (value) => {
  var expRegNombre=/^[a-zA-ZÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  if (
    // Unicamente acepta letras
    expRegNombre.exec(value)
    // El nombre tiene que tener la primera letra en mayuscula.
    // && value.charAt(0).toUpperCase() == value.charAt(0)
  ){
    return true;
  }
  return false;
}

//Función para validar RFC vs FeNac
export const isRfcSameAsFeNac = (param) => (value) => {
  
  // console.log("Param:")
  // console.log(param)
  // console.log("Value:")
  // console.log(value)

  let rfc = value;
  let strFeNacRfc = rfc.substr(4,6);

  let date = param;

  let year = date.substring(2,4)
  let month = date.substring(5,7)
  let day = date.substring(8,10)
  let strBornDate = year + month + day;

  // console.log("BornDate:" + strBornDate.toString())
  // console.log("StrFeNacRfc:" + strFeNacRfc.toString())
  
  // console.log("strBornDate: " + strBornDate + " strFeNacRfc: " + strFeNacRfc);
  if(strBornDate.toString() === strFeNacRfc.toString()){
    // console.log("son iguales");
    return true;
  } else {
    return false;
  }
}

export const auth = { auth: { username: 'ardash.hernandez@thbmexico.com', password: 'Abcd1234*' } }

export const APIServices = {
  endpoint : APIEndpoint.substring(0,APIEndpoint.length-4),
  countries: "country/",
  email_confirm: 'email-confirm/',
  menu_page: "menu-page/",
  frecuent_questions: "frecuent-questions/",
  products: "products",
  product_categories: "product-categories/",
  products_requirements: "products-requirements/",
  token: "get-token-auth/",
  validate_human_user: "gr-vhu/",
  user: "user/",
  user_quick: "user_quick/",
  email: "email/",
  reset_password: "reset-password/",
  start_password_reset: "start-password-reset/",
  packages_from_responses: "packages-from-responses/",
  gender: "gender/",
  civil_status: "civil-status/",
  zip_code: "catalog/zip-code/",
  user_address: "user_address/",
  quote: "quote/",
  quote_detail: "quote-detail/",
  product_requirements: "product-requirements/",
  product_payment: "product-payment/",
  
  // permission: "http://192.168.100.84:8080/DB/permission.json",
  /* Payments */
  resend_thona_pin_code: "resend-thona-pin-code/",
  payment_generation: "payment-generation/",
  url_payment: "general/url_payment/",
  

  payment_process: "payment-process/",

  continental_destinies: "catalog/continental-destinies/",
  
  //! WTF Variables
  quotecategory:"chat/quotecategory/",
  get_info_insurance_pdfs: "chat/getServicesandClaimsPdfs/",
  get_info_users: "chat/getUsersData/",
  
  click_watcher: "general/click_watcher/",
  
  send_email_contact: "insurance/api/send_email_contact/",

  //? Catalogos Fiscales
  catalog_fiscal: "catalog/fiscal/",
  catalog_fiscal_dentegra: "catalog/fiscal_dentegra/",

};