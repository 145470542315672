import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import BuyProcessStore from "./Modules/BuyProcessStore";
import SessionStore from "./Modules/SessionStore.js";
import GeneralConfigStore from "./Modules/GeneralConfigStore.js";
import ShoppingCarStore from "./Modules/ShoppingCarStore.js";

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [new VuexPersistence().plugin],
  // plugins: [],
  modules: {
    BuyProcessStore: BuyProcessStore,
    GeneralConfigStore: GeneralConfigStore,
    SessionStore: SessionStore,
    ShoppingCarStore: ShoppingCarStore
  }
});
