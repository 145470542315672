/* eslint-disable prettier/prettier */
import Vue from 'vue'
import { APIServices } from '../../constants/config';
class ShoppingCar {
    quote = {};
    quoteDetails = {}
    carItems = [];
    constructor(itemList) {
        if (ShoppingCar.instance) {
            return ShoppingCar.instance;
        }
        this.carItems = itemList ? itemList : [];
        ShoppingCar.instance = this;
        return this;
    }
    setQuoteData(data) {
        this.quote = data;
    }
    getItems() {
        return this.carItems;
    }

    async getQuoteWithDetails() {
        let url = APIServices.quote_detail + this.quote.id + "/";
        let $http = Vue.prototype.$http;
        return await $http.get(url).then(response => {
            this.quoteDetails = response.data;
            return response.data
        });
    }

    makeOwn() {
        let url = APIServices.quote + this.quote.id + "/";
        let $http = Vue.prototype.$http;
        $http.patch(url, {}).then(response => this.quote = response.data)
        //.catch((error) => {
        .catch(() => {
            //console.log("No se pudo hacer propia la cotización");
        })
    }

    addItems(newData) {
        let finded = this.carItems.filter(item => {
            return item.id == newData.id;
        });
        if (!finded.length) {
            this.carItems.push(newData);
        }
        let url = APIServices.quote + this.quote.id + "/";
        let $http = Vue.prototype.$http;

        let selectedQuoteCategory = this.quote.listed_categories.filter(item => item.category == newData.id)[0];
        let selectedProduct = newData.products.filter(item => item.id == newData.packageSelectedId)[0];
        let data = {
            listed_categories: [{
                id: selectedQuoteCategory.id,
                product: newData.packageSelectedId,
                extra_data: selectedProduct.extra_data,
                step: "SCAR"
            }]
        };
        $http.patch(url, data).catch((error) => {
            // console.log("Error añadiendo items", error);
            throw error
        })
    }

    removeItems(itemData) {
        for (var i = 0; i < this.carItems.length; i++) {
            if (itemData.id == this.carItems[i].id) {
                this.carItems.splice(i, 1);
                i--;
            }
        }
        let url = APIServices.quote + this.quote.id + "/";
        let $http = Vue.prototype.$http;

        let data = {
            listed_categories: [{
                id: this.quote.listed_categories.filter(item => item.category == itemData.id)[0].id,
                product: null,
                extra_data: {},
                step: "QUOD"
            }]
        };
        //$http.patch(url, data).catch((error) => {
        $http.patch(url, data).catch(() => {
            // console.log("Error eliminando items");
            // throw error
        })

        return this.carItems;
    }

    generatePaymentIntent(quoteCategory) {
        //console.log("Creando un intento de pago", quoteCategory);
        let url = APIServices.payment_generation + quoteCategory.id + "/";
        // console.log("generatePaymentIntent");
        console.log(url);
        let $http = Vue.prototype.$http;
        return $http.patch(url).catch(() => {})
    }


    sendPaymentUrl(email , payment_url) {
        console.log("Init sendPaymentUrl")
        let url = APIServices.url_payment;
        let $http = Vue.prototype.$http;
        var body = {
            "email":email,
            "payment_url": payment_url
        }

        return $http.patch(url , body).catch((error) => {
            console.log(error)
        })
    }

    generatePaymentProcessIntent(quoteCategory , data) {
        //console.log("Creando un intento de pago", quoteCategory);
        let url = APIServices.payment_process + quoteCategory.id + "/";
        let $http = Vue.prototype.$http;

        //return $http.patch(url).catch((error) => {
        return $http.patch(url , data).catch(() => {
            // console.log("Ha habido un error al general el pago");
        });
    }
    
    completePurchase() {
        // console.log("Completando compra")
    }

    reset() {
        this.quote = {};
        this.quoteDetails = {}
        this.carItems = [];
    }
}
export default ShoppingCar