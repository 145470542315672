/* eslint-disable prettier/prettier */
import Vue from "vue";
import Vuex from "vuex";
import { APIServices } from '../../constants/config';

Vue.use(Vuex);

export default {
  state: {
    shoppingList: [],
    productCategories: null, // Instance of ProductCategories
    selectedProductCategories: [],
    filteredProducts: [], // Los productos filtrados son productos que fueron seleccionados por el cliente o calculados para el cliente
    requiredDataFromUser: [],
    countries: [],
    menu_page: [],
    products: [],
    userAnswers: [],
    products_requirements: [],
    products_requirements_for_to_sugestion: [],
    lastQuoteData: {}
  },
  getters: {
    products: state => state.products,
    productCategories: state => state.productCategories,
    lastQuoteData: state => state.lastQuoteData
  },
  mutations: {
    updateLastQuoteData(state, payload) {
      state.lastQuoteData = payload;
    },
    updateRequiredDataFromUser(state, newData) {
      state.requiredDataFromUser = newData.data;
    },
    updateSelectedProductCategories(state, newData) {
      state.selectedProductCategories = newData.data;
    },
    updateShoppingList(state, newData) {
      state.shoppingList = newData.data;
    },
    updateFilteredProducts(state, newData) {
      //console.log("updateFilteredProducts newData: " + JSON.stringify(newData));
      state.filteredProducts = newData.data;
    },
    removeFromShoppingList(state, itemData) {
      for (var i = 0; i < state.shoppingList.length; i++) {
        if (itemData.id == state.shoppingList[i].id) {
          state.shoppingList.splice(i, 1);
          i--;
        }
      }
    },
    addToShoppingList(state, itemData) {
      //console.log("Actualizando state.shoppingList", state.shoppingList);
      let finded = state.shoppingList.filter(item => {
        return item.id == itemData.id;
      });
      if (!finded.length) {
        state.shoppingList.push(itemData);
      }
    },

    updateCountries(state, newData) {
      state.countries = newData.data;
    },
    updateMenu_page(state, newData) {
      state.menu_page = newData.data;
    },
    updateProducts(state, newData) {
      state.products = newData.data;
    },
    updateProductCategories(state, newData) {
      state.productCategories = newData.data;
    },
    updateProductsRequirements(state, newData) {
      state.products_requirements = newData.data;
    },
    updateProductsRequirementsForToSugestion(state, newData) {
      state.products_requirements_for_to_sugestion = newData.data;
    },
  },
  actions: {
    async sendConfirmEmail(_, params) {
      let $http = Vue.prototype.$http;
      let url = APIServices.email_confirm;
      return await $http.post(url, JSON.stringify(params.data));
    },
    async getMenuPage({ commit }, params) {
      let $http = Vue.prototype.$http;
      let url = APIServices.menu_page;

      return await $http.get(url, {
          params: params,
        })
        .then(result => {
          commit("updateMenuPage", {
            data: result.data.data
          });
          return result.data.data;
        });
    },
    async getProducts({ commit }, params={}) {
      let $http = Vue.prototype.$http;
      let url = APIServices.products;
      //console.log("url: " + JSON.stringify(url));
      return await $http
        .get(url, {
          params: params.filters,
        })
        .then(result => {
          commit("updateProducts", {
            data: result.data.data
          });
          return result.data.data;
        });
    },
    async getProductCategories(_, payload={}) {
      let $http = Vue.prototype.$http;
      let url = APIServices.product_categories;
      //console.log("payload", payload);
      return await $http
        .get(url, {
          params: payload.filters,
        })
        .then(result => result.data.data);
    },
    async getProductsRequirements({ commit }, params={}) {
      let $http = Vue.prototype.$http;
      let url = APIServices.products_requirements;
      return await $http
        .get(url, {
          params: params.filters,
        })
        .then(result => {
          if (params.filters["is_for_to_suggestion"]) { // Si se tiene este parámetro, las preguntas se guardan como para sugerencia
            commit("updateProductsRequirementsForToSugestion", {
              data: result.data.data
            });
          } else {
            commit("updateProductsRequirements", {
              data: result.data.data
            });
          }
          return result.data.data;
        });
    },
    //Obtiene los productos/paquetes calculados correspondientes a las respuestas del usuario
    async calculateProductsPackages({ commit }, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.packages_from_responses;
      //console.log("VOY A LLAMAR A LA URL: " + url)
      //console.log("DATA A ENVIAR A: " + JSON.stringify(payload.data));
      commit("updateLastQuoteData", payload.data)
      
      //Envía dayload.data -> requeriments al servidor para obtener los pqauetes del seguro
      return await $http
        .post(url, payload.data)
        .then(result => {
          console.log("Tag X2")
          console.log(result.data)
          return result.data
        })
        // .catch((error) => {
        //   console.log("Tag X3")
        //   console.log(error)
        // })
    },
    async fillProductRequirements(_, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.product_requirements + payload.id + "/";

      //console.log("url: " + url);
      return await $http
        .patch(url, payload.data)
        .then(result => result.data)
    },
    async fillProdRequirementsWithFile(_, payload) {
      let $http = Vue.prototype.$http;
      let url = APIServices.product_requirements + payload.id + "/";

      //Utiliza el obj FormData para enviar los archivos subidos
      let formData = new FormData();
      formData.append(payload.files[0].filename, payload.files[0].file);
      formData.append(payload.files[1].filename, payload.files[1].file);
      formData.append(payload.files[2].filename, payload.files[2].file);
      
      //Parsea el JSON (extra_data) que traemos de la forma para
      //y envía el par clave:valor, agregamos JSON.stringify x q
      //traemos objs anidados de lo contrario en el Back veremos
      //esos objs/arrays como [object object]
      for (let key in payload.data) {
        switch(key)
        {
          case "zip_code":{
            formData.append(key, JSON.stringify(payload.data[key]));
            break;
          }
          case "civil_status":{
            formData.append(key, JSON.stringify(payload.data[key]));
            break;
          }
          case "colony":{
            formData.append(key, JSON.stringify(payload.data[key]));
            break;
          }
          case "gender":{
            formData.append(key, JSON.stringify(payload.data[key]));
            break;
          }
          case "occupation":{
            formData.append(key, JSON.stringify(payload.data[key]));
            break;
          }
          case "state":{
            formData.append(key, JSON.stringify(payload.data[key]));
            break;
          }
          case "town_council":{
            formData.append(key, JSON.stringify(payload.data[key]));
            break;
          }
          default:{
            formData.append(key, payload.data[key]);
            break;
          }
        }
      }
      
      //Envia los datos al servidor
      return await $http
        .patch(url, formData, {headers: {
          'Content-Type': 'multipart/form-data'
        }})
        .then(result => result.data)
    }
  }
}