class ProductCategories {
  categoriesList = [];
  userSelection = [];
  constructor(itemList) {
    if (ProductCategories.instance) {
      return ProductCategories.instance;
    }
    this.categoriesList = itemList;
    ProductCategories.instance = this;
    return this;
  }

  setUserSelection(userSelectionList) {
    this.userSelection = userSelectionList;
  }
  getUserSelection() {
    return this.userSelection;
  }
  getNUserSelection() {
    let userSelectionIds = this.userSelection
      ? this.userSelection.map(item => item.id)
      : [];
    return this.categoriesList.filter(
      item => !userSelectionIds.includes(item.id)
    );
  }
  getItems() {
    //console.log("Devolviendo los items");
    return this.categoriesList;
  }

  addSelectedItems(newData) {
    //console.log("Add", newData);
    let finded = this.categoriesList.filter(item => {
      return item.id == newData.id;
    });
    if (!finded.length) {
      this.userSelection.push(newData);
    }
  }

  removeItem(itemData) {
    for (var i = 0; i < this.userSelection.length; i++) {
      if (itemData.id == this.userSelection[i].id) {
        this.userSelection.splice(i, 1);
        i--;
      }
    }
    return this.userSelection;
  }

  reset() {
    this.userSelection = [];
  }
}
export default ProductCategories;
